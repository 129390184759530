import { createSlice } from "@reduxjs/toolkit";
import { DefaultSession, ISODateString } from "next-auth";
import { useDispatch } from "react-redux";

export type NullableSession = DefaultSession | null;

export type User = {
  name?: string | null;
  email?: string | null;
  image?: string | null;
};

export type UserSessionData = {
  user: User | null;
  expires: ISODateString | null;
};

export type UserState = {
  session: UserSessionData | null;
  userData: Record<string, any>;
  loggedIn: boolean;
  errors: string;
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    session: {
      user: {
        name: null,
        email: null,
        image: null,
      },
      expires: null,
    },
    userData: {
      initials: "", // Initialize initials as an empty string
      firstName: "",
      lastName: "",
      id: "",
      email: "",
    },
    loggedIn: false,
    errors: "",
  },
  reducers: {
    setUserSession: (state, action) => {
      state.session = action.payload;
    },
    setUserData: (state, action) => {
      //console.log("setUserData99", action.payload)
      //console.log("type of emailVerified", typeof action.payload.emailVerified)
      if (Object.keys(action.payload).length === 0) {
        state.userData = {
          id: "",
          initials: "", // Initialize initials as an empty string
          firstName: "",
          lastName: "",
          email: "",
        };
      } else {
        state.userData = { ...state.userData, ...action.payload }; //update the userData object with the new data
        //console.log("userData is now",state.userData)
      }
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setInitials: (state, action) => {
      // Extract the first letter of the first name and last name
      ////console.log("INITIALS",action.payload)
      const { firstName, lastName } = action.payload;
      const firstInitial = firstName ? String(firstName.charAt(0)).toUpperCase() : "";
      const lastInitial = lastName ? String(lastName.charAt(0)).toUpperCase() : "";

      // Create or update the 'initials' key in the userData object
      state.userData.initials = firstInitial + lastInitial;
    },
    update: (state, action) => {
      state.loggedIn = action.payload.loggedIn;
      state.errors = action.payload.errors;
    },
  },
});

export const { setUserSession, setInitials, setUserData, setLoggedIn, setErrors, update } = userSlice.actions;
export default userSlice.reducer;
