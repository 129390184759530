import { createSlice } from "@reduxjs/toolkit";

// Define types for 'entry' and 'reports' states
export type CloudState = {
  cloudState: number;
  loadingMessage: string;
  lastSaved: Date | null;
};

const cloudSlice = createSlice({
  name: "cloud", // Name of the slice
  initialState: {
    cloudState: 0, // -1, is error, 0 is not synced, 1 is syncing, 2 is synced,
    cloudMessage: "Ready to save to cloud",
    lastSaved: null,
  },
  reducers: {
    // This is an example of [type] syntax in a reducer function
    setCloudState: (state, action) => {
      state.cloudState = action.payload;
    },
    setCloudMessage: (state, action) => {
      state.cloudMessage = action.payload;
    },
    setLastSaved: (state, action) => {
      state.lastSaved = action.payload;
    },
  },
});

export const { setCloudState, setCloudMessage, setLastSaved } = cloudSlice.actions;
export default cloudSlice.reducer;
