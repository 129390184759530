"use client";

// Make sure to import React and other dependencies
import React, { PropsWithChildren } from "react";
import { Provider } from "react-redux";
import store from "@/reduxState/store";

export default function ReduxProvider({ children }: PropsWithChildren) {
  return <Provider store={store}>{children}</Provider>;
}
