import { createSlice } from "@reduxjs/toolkit";

// Define types for 'entry' and 'reports' states
export type EntriesState = {
  archivedEntries: string[];
};

const entriesSlice = createSlice({
  name: "entries", // Name of the slice
  initialState: {
    archivedEntries: [],
  },
  reducers: {
    setArchivedEntries: (state, action) => {
      state.archivedEntries = action.payload;
    },
    resetEntriesState: (state) => {
      state.archivedEntries = [];
    },
  },
});

export const { setArchivedEntries, resetEntriesState } = entriesSlice.actions;
export default entriesSlice.reducer;
