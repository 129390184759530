import { ChevronLeft, ChevronRight, Moon, Sun, Laptop } from "lucide-react";

export const Icons = {
  ChevronLeft,
  ChevronRight,
  Sun,
  Moon,
  Laptop,
};

export default Icons;
